import React, { memo, useEffect, useRef } from "react";
import * as Yup from "yup";
import {
  EmailSchema,
  FaqInputSchema,
  LiveChatSchema,
  MobileSchema,
  NameSchema,
  NumberSchema,
  OtpSchema,
  TextSchema,
  UrlSchema,
  DatePickerSchema,
} from "../utils/ValidationSchema";

import uniqid from "uniqid";
import { HostWindowCommService } from "../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../Services/IframeHostService/models";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  allMessagesStore,
  removeMessage,
  setAllMessages,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { socketStatusStore } from "../../Store/Slices/socket/SocketSlice";
import {
  AppFlowCustomField,
  AppFlowEmailField,
  AppFlowFaqQueryField,
  AppFlowLiveChatField,
  AppFlowMobileField,
  AppFlowNameField,
  AppFlowNumberField,
  AppFlowOTPField,
  AppFlowTextField,
  AppFlowUploadField,
  AppFlowUrlField,
  AppForm,
} from "../appComponents/Forms";
import { getMessageMediaType } from "../appComponents/Forms/livechat/utils";
import { AppRenderTransferToFaqButton } from "../appComponents/RenderComponents";
import {
  INPUT_TYPES,
  MESSAGE_TYPES,
  SOCKET_CONNECTION_TYPES,
} from "../utils/Enum";
import {
  checkQuickQuickActionFaqButtonEnable,
  getCurrentUTCTime,
  isEmptyObject,
  renderTextWithLinks,
  stringToRegex,
} from "../utils/utils";
import useInternetConnection from "../hooks/useInternetConnection";
import useTimerRef from "../hooks/useTimerRef";
import useMessagesData from "../hooks/useMessagesData";
import AppFlowNewDatePicker from "../appComponents/Forms/AppFlowNewDatePicker";

const AUTO_CLOSE_TIMEOUT = 24 * 60 * 60 * 1000; // 24 hours

interface Props {
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppInputHandler: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { botConfig } = useAppSelector(useBotConfigStore);
  const {
    lastNodeResponse,
    lastInputBoxType,
    lastQuickActions,
    sessionId,
    liveChatConnected,
    typingStatus,
    allMessages
  } = useAppSelector(allMessagesStore);
  const { socket, executeConnectSocketEventWithReConnectUser } = useAppSelector(socketStatusStore);
  const { isOnline } = useInternetConnection();

  const inactivityConfig = botConfig?.config?.inactivity_timeouts?.closed_chat ?? 0;  

  let lastMessageRef = useRef<any>(allMessages?.[allMessages?.length - 1]);
  // Update the ref whenever allMessages changes to avoid closures scopes below.
  useEffect(() => {
    lastMessageRef.current = allMessages?.[allMessages?.length - 1];
  }, [allMessages]);


  const { resetTimer } = useTimerRef({
    timeout: ((inactivityConfig?.minutes && Number(inactivityConfig?.minutes) * 60000) + (inactivityConfig?.hours && Number(inactivityConfig?.hours) * 60 * 60000)) || AUTO_CLOSE_TIMEOUT,
    callback: handleNoActivityEvent,
  });

  let commService = React.useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  const isGlobalUserInputEnabled: boolean =
    botConfig?.config?.global_user_input_enabled ?? false;

  const isAnythingElseBtnEnabled: boolean =
    checkQuickQuickActionFaqButtonEnable(lastQuickActions) || false;
  
    const { postBotUserQuery } = useMessagesData();
  
  

  //* handle Momato tracking actions
  const handleTrackingActions = (actions: any[]) => {
    if (actions?.length > 0) {
      const trackingActions = actions?.filter(
        (a: any) => a?.type === "tracking"
      );
      if (trackingActions?.length > 0) {
        commService.postMessage({
          event_type: OEvents.TRACKING_EVENTS,
          data: trackingActions,
        });
      }
    }
  };

  const handleOfflineEvent = () => {
    if (!isOnline) return;
    socket?.disconnect();
    setTimeout(() => {
      // socket?.connect();
      executeConnectSocketEventWithReConnectUser();
    }, 500);
  };

  function handleNoActivityEvent() {
    if ( !inactivityConfig?.enabled || !liveChatConnected) return;

    if (lastMessageRef.current?.isUser) {
      resetTimer();
      return;
    };

    if (inactivityConfig?.enabled) {
      let data = {
        session_id: sessionId,
        conversation_id: lastNodeResponse?.conversation_id,
        time: getCurrentUTCTime(),
        reason: "chat is auto closed due to non activity of the user."
      }

      if (socket?.connected) {
        console.warn("emitting socket event ", data)
        socket?.emit(SOCKET_CONNECTION_TYPES.CHAT_AUTO_CLOSE, data);
      }
    }
  }

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    SubmitProps?.validateForm();
    SubmitProps?.setSubmitting(false);

    // do not submit anything if typing status is true
    if (typingStatus?.length > 0) {
      return;
    }

    // if (!socket?.id || !socket?.connected || !isOnline) {
    //   return handleOfflineEvent()
    // }
    if (!isOnline) {
      return handleOfflineEvent()
    }

    if (values.hasOwnProperty("type") && values?.["type"] === "file") {
      handleFileInput(values);
      return;
    }

    handleTextResponse(values);

    if (lastNodeResponse?.actions?.length > 0) {
      handleTrackingActions(lastNodeResponse?.actions);
    }

    setTimeout(() => {
      SubmitProps.resetForm();
    }, 100);
  };

  const handleLiveChatSubmitBtn = (values: any, SubmitProps: any) => {
    SubmitProps?.validateForm();
    SubmitProps?.setSubmitting(false);

    if (!socket?.id || !socket?.connected || !isOnline) {
      return handleOfflineEvent()
    }

    if (values?.media?.source && values?.media?.name) {
      handleMediaResponse(values);
    } else {
      handleTextResponse(values, "livechat");
    }

    setTimeout(() => {
      resetTimer();
      SubmitProps.resetForm();
    }, 100);
  };

  const handleFileInput = (file: any) => {
    let data = {
      type: MESSAGE_TYPES.FILE,
      isUser: true,
      value: {
        url: file?.attachment?.source || file?.attachment?.src,
        name: file?.attachment?.name || "Attachment",
      },
      attachment: file?.attachment,
      socket_id: socket?.id,
      session_id: sessionId,
      conversation_id: lastNodeResponse?.conversation_id,
      time: getCurrentUTCTime(),
    };

    dispatch(setAllMessages([data]));
    // if (socket?.connected) {
    //   socket?.emit(SOCKET_CONNECTION_TYPES.BOT_CONVERSATION, data);
    // }

    postBotUserQuery(data);

    
  };

  const handleTextResponse = (values: any, emitType: string = "bot") => {
    const emitEvent =
      emitType === "livechat"
        ? SOCKET_CONNECTION_TYPES.AGENT_CHAT
        : SOCKET_CONNECTION_TYPES.BOT_CONVERSATION;

    let userInput: string = "";

    if (typeof values === "string") {
      userInput = values || "";
    } else if (typeof values === "object" && !isEmptyObject(values)) {
      let firstValue: string = String(Object?.values(values)[0]) || "";
      userInput = firstValue;
    } else {
      userInput = "";
    }
    let data = {
      type: MESSAGE_TYPES.TEXT,
      isUser: true,
      value: renderTextWithLinks(userInput),
      socket_id: socket?.id,
      session_id: sessionId,
      conversation_id: lastNodeResponse?.conversation_id,
      time: getCurrentUTCTime(),
      message_id: uniqid(),
    };
    dispatch(setAllMessages([data]));
    
    if (emitType === "bot") {
      postBotUserQuery(data);
      return;
    }

    if(emitType === "livechat" && socket?.connected){
      socket?.emit(emitEvent, data,(error: any, response: any) => {
        if (error) {
          dispatch(removeMessage(data));
        }
      });
    }
    return;
  };

  const handleMediaResponse = (values: any) => {
    let mediaObj = {};
    if (values?.media?.source) {
      mediaObj = {
        type: getMessageMediaType(values?.media?.type),
        isUser: true,
        value: {
          type: getMessageMediaType(values?.media?.type),
          text: renderTextWithLinks(String(Object?.values(values)[0])) || "",
          url: values?.media?.source,
          fileName: values?.media?.name,
        },
        time: getCurrentUTCTime(),
        userId: socket?.id,
        session_id: sessionId,
        conversation_id: lastNodeResponse?.conversation_id,
        message_id: uniqid(),
      };
      dispatch(setAllMessages([mediaObj]));
      if (socket?.connected) {
        socket?.emit(SOCKET_CONNECTION_TYPES.AGENT_CHAT, mediaObj,(error: any, response: any) => {
          if (error) {
            dispatch(removeMessage(mediaObj));
          }
        });
      }
      return;
    }

    return;
  };

  if (lastInputBoxType === INPUT_TYPES.NONE || allMessages?.length === 0) {
    return null;
  }

  if (lastInputBoxType === INPUT_TYPES.NAME) {
    return (
      <AppForm
        initialValues={{ name: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={NameSchema}
      >
        <AppFlowNameField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.EMAIL) {
    return (
      <AppForm
        initialValues={{ email: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={EmailSchema}
      >
        <AppFlowEmailField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.MOBILE) {
    return (
      <AppForm
        initialValues={{ mobile: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={MobileSchema}
      >
        <AppFlowMobileField />
      </AppForm>
    );
  }
  if (lastInputBoxType === INPUT_TYPES.OTP) {
    return (
      <AppForm
        initialValues={{ otp: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={OtpSchema}
      >
        <AppFlowOTPField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.NUMBER) {
    return (
      <AppForm
        initialValues={{ number: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={NumberSchema}
      >
        <AppFlowNumberField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.TEXT) {
    return (
      <AppForm
        initialValues={{ textField: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={TextSchema}
      >
        <AppFlowTextField />
      </AppForm>
    );
  }
  if (lastInputBoxType === INPUT_TYPES.ALPHANUMERIC) {
    return (
      <AppForm
        initialValues={{ textField: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={TextSchema}
      >
        <AppFlowTextField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.ALPHANUMERIC) {
    return (
      <AppForm
        initialValues={{ textField: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={TextSchema}
      >
        <AppFlowTextField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.WEBSITE) {
    return (
      <AppForm
        initialValues={{ url: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={UrlSchema}
      >
        <AppFlowUrlField />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.FILE_UPLOAD) {
    let mediaExtensions: any[] = lastNodeResponse?.allowedFileTypes ?? [];
    return (
      <AppForm
        initialValues={{ attachment: "", type: "file" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
      >
        <AppFlowUploadField mediaExtensions={mediaExtensions} />
      </AppForm>
    );
  }

  if (lastInputBoxType === INPUT_TYPES.CUSTOM) {
    const regexString: any = stringToRegex(lastNodeResponse?.customRegex);

    const customValidationSchema = Yup.object().shape({
      custom: Yup.string()
        .required()
        .matches(regexString, "Please enter a valid input")
        .label("Input"),
    });
    return (
      <AppForm
        initialValues={{ custom: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={customValidationSchema}
      >
        <AppFlowCustomField />
      </AppForm>
    );
  }

  if (liveChatConnected && lastInputBoxType === INPUT_TYPES.LIVECHAT) {
    return (
      <AppForm
        initialValues={{ livechat: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleLiveChatSubmitBtn(values, submitProps);
        }}
        validationSchema={LiveChatSchema}
      >
        <AppFlowLiveChatField />
      </AppForm>
    );
  }

  if (
    !lastInputBoxType &&
    !isGlobalUserInputEnabled &&
    isAnythingElseBtnEnabled
  ) {
    return <AppRenderTransferToFaqButton />;
  }

  if (lastInputBoxType === INPUT_TYPES.FAQ) {
    return (
      <AppForm
        initialValues={{ query: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={FaqInputSchema}
      >
        <AppFlowFaqQueryField />
      </AppForm>
    );
  }

  //global user input
  if (!lastInputBoxType && isGlobalUserInputEnabled) {
    return (
      <AppForm
        initialValues={{ query: "" }}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={FaqInputSchema}
      >
        <AppFlowFaqQueryField />
      </AppForm>
    );
  }

  // Date Picker
  if (lastInputBoxType === INPUT_TYPES.DATE) {
    return (
      <AppForm
        initialValues={{ 
          dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
          formattedDateRange: '' 
        }}
        validationSchema={DatePickerSchema}
        onSubmit={(values: any, submitProps: any) => {
          const submissionValues = { dateRange: values.formattedDateRange };
          handleSubmitBtn(submissionValues, submitProps);
        }}
      >
        <AppFlowNewDatePicker />
      </AppForm>
    );
  }

  //* return null if not matches anything
  return null;
};

export default memo(AppInputHandler);