import React, { useState } from 'react';
import { FastField, getIn, useFormikContext } from "formik";
import { DateRange as DateRangeOriginal } from "react-date-range";
import { addDays } from "date-fns";
import { enUS } from 'date-fns/locale';
import moment from 'moment';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useAppSelector } from '../../../Store';
import { allMessagesStore } from '../../../Store/Slices/socket/AllMessagesSlice';
import { StyledWidgetInputBox } from "../../Styles/StyledForms";
import AppErrorMessage from "./AppErrorMessage";
import AppSendSubmitButton from "./AppSendSubmitButton";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { StyledInputField } from "../../Styles/StyledForms";
import AppSmallButton from '../UtilsComponents/AppSmallButton';

const DateRange = DateRangeOriginal as any;

interface Props {
  name?: string;
  label?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
  ref?: any;
}

const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DatePickerDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme?.palette?.primary?.main} !important;
  border-radius: 43px;
  height: 40px;
  position: relative;
`;

const DatePickerWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1300;
  bottom: 4px;
  left: 4px;
  right: 4px;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(101%)'};
  transition: transform 0.3s ease-out;
  max-height: calc(100% - 8px);
  height: calc(100% - 8px);
  overflow-y: auto;
  padding: 0px;

  .rdrMonth {
    margin: 0 auto;
    width: 100%;
  }

  .rdrDateDisplayWrapper {
    background-color: ${theme.palette.default.white};
  }

  .rdrWeekDays {
    background-color: ${theme.palette.default.lightGrey};
    margin-bottom: 22px !important;
  }

  .rdrDayDisabled {
    background-color: ${theme.palette.default.lightGrey} !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px;
  border-top: 1px solid ${theme.palette.default.lightGrey};
`;

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.palette.default.black}80;
  z-index: 1200;
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
`;

const AppFlowNewDatePicker: React.FC<Props> = ({
  name = "dateRange",
  label = "Select Date",
  ...otherProps
}) => {
  const { errors, touched, values, setFieldValue, submitForm } = useFormikContext<any>();
  const { typingStatus, lastNodeResponse } = useAppSelector(allMessagesStore);
  const [isOpen, setIsOpen] = useState(false);
  const [hasSelected, setHasSelected] = useState(false);
  const [dateError, setDateError] = useState<string | null>(null);

  // Calculate dates based on type (CUSTOM, NEXT, LAST)
  const calculateDate = (dateInfo: any) => {
    if (!dateInfo) return new Date();
    
    try {
      if (dateInfo.type === "CUSTOM" && dateInfo.date) {
        const [day, month, year] = dateInfo.date.split('/');
        const customDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
        return isNaN(customDate.getTime()) ? new Date() : customDate;
      }
      
      const currentDate = new Date();
      
      if (dateInfo.type === "LAST" && dateInfo.number && dateInfo.unit) {
        if (dateInfo.unit === "days") {
          return addDays(currentDate, -Math.abs(dateInfo.number));
        }
        if (dateInfo.unit === "months") {
          const result = new Date(currentDate.getFullYear(), currentDate.getMonth() - Math.abs(dateInfo.number), 1);
          return isNaN(result.getTime()) ? currentDate : result;
        }
        if (dateInfo.unit === "years") {
          const result = new Date(currentDate.getFullYear() - Math.abs(dateInfo.number), 0, 1);
          return isNaN(result.getTime()) ? currentDate : result;
        }
      }
      
      if (dateInfo.type === "NEXT" && dateInfo.number && dateInfo.unit) {
        if (dateInfo.unit === "days") {
          return addDays(currentDate, Math.abs(dateInfo.number));
        }
        if (dateInfo.unit === "months") {
          const result = new Date(currentDate.getFullYear(), currentDate.getMonth() + Math.abs(dateInfo.number) + 1, 0);
          return isNaN(result.getTime()) ? currentDate : result;
        }
        if (dateInfo.unit === "years") {
          const result = new Date(currentDate.getFullYear() + Math.abs(dateInfo.number), 11, 31);
          return isNaN(result.getTime()) ? currentDate : result;
        }
      }
      
      return currentDate;
    } catch (error) {
      return new Date();
    }
  };

  // Get the from and to dates from lastNodeResponse
  const fromDate = lastNodeResponse?.from ? calculateDate(lastNodeResponse.from) : new Date();
  const toDate = lastNodeResponse?.to ? calculateDate(lastNodeResponse.to) : addDays(new Date(), 7);

  // Ensure valid date range
  const validFromDate = isNaN(fromDate.getTime()) ? new Date() : fromDate;
  const validToDate = isNaN(toDate.getTime()) ? addDays(new Date(), 7) : toDate;
  
  // Ensure toDate is not before fromDate
  const finalToDate = validToDate < validFromDate ? addDays(validFromDate, 7) : validToDate;

  // Handle excluded days
  const isDateDisabled = (date: Date) => {
    if (!lastNodeResponse?.excluded) return false;
    
    // Check if the day of the week is excluded
    if (lastNodeResponse.excluded.days && lastNodeResponse.excluded.days.length > 0) {
      const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
      if (lastNodeResponse.excluded.days.includes(dayOfWeek)) {
        return true;
      }
    }
    
    // Check if the specific date is excluded
    if (lastNodeResponse.excluded.dates && lastNodeResponse.excluded.dates.length > 0) {
      const dateString = date.toISOString().split('T')[0];
      return lastNodeResponse.excluded.dates.some((excludedDate: any) => {
        const excludedDateString = new Date(excludedDate).toISOString().split('T')[0];
        return dateString === excludedDateString;
      });
    }
    
    return false;
  };

  // Check if today is excluded
  const isTodayExcluded = () => {
    const today = new Date();
    return isDateDisabled(today);
  };

  const dateRange = getIn(values, name) || { 
    startDate: null,
    endDate: null,
    key: 'selection'
  };

  const handleSelect = (ranges: any) => {
    if (lastNodeResponse?.dateRange === false) {
      setFieldValue(name, {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.startDate,
        key: 'selection'
      });
    } else {
      setFieldValue(name, {
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: 'selection'
      });
    }
  };

  const handleDateClick = (day: Date) => {
    if (lastNodeResponse?.dateRange === false) {
      setFieldValue(name, {
        startDate: day,
        endDate: day,
        key: 'selection'
      });
    }
  };

  // Format date according to the specified format
  const formatDateWithFormat = (date: Date, format: string = 'mm/dd/yyyy') => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const fullYear = date.getFullYear().toString();
    const shortYear = fullYear.slice(2);
    
    switch (format) {
      case 'mm/dd/yyyy':
        return `${month}/${day}/${fullYear}`;
      case 'mm/dd/yy':
        return `${month}/${day}/${shortYear}`;
      case 'dd/mm/yyyy':
        return `${day}/${month}/${fullYear}`;
      case 'dd/mm/yy':
        return `${day}/${month}/${shortYear}`;
      case 'dd-mm-yyyy':
        return `${day}-${month}-${fullYear}`;
      case 'dd-mm-yy':
        return `${day}-${month}-${shortYear}`;
      case 'mm-dd-yyyy':
        return `${month}-${day}-${fullYear}`;
      default:
        return `${month}/${day}/${fullYear}`;
    }
  };

  const formatDateDisplay = () => {
    if (!hasSelected || !dateRange.startDate) return "Select Date";
    
    const format = lastNodeResponse?.dateFormat || 'mm/dd/yyyy';
    const startFormatted = formatDateWithFormat(dateRange.startDate, format);
    
    if (lastNodeResponse?.dateRange === false) {
      return startFormatted;
    }
    
    if (!dateRange.endDate) return "Select date range";
    const endFormatted = formatDateWithFormat(dateRange.endDate, format);
    return `${startFormatted} - ${endFormatted}`;
  };

  const formatDateRangeForSubmission = () => {
    if (!dateRange.startDate) return "";
    
    const format = lastNodeResponse?.dateFormat || 'mm/dd/yyyy';
    const startFormatted = formatDateWithFormat(dateRange.startDate, format);
    
    if (lastNodeResponse?.dateRange === false) {
      return startFormatted;
    }
    
    if (!dateRange.endDate) return "";
    const endFormatted = formatDateWithFormat(dateRange.endDate, format);
    return `${startFormatted} - ${endFormatted}`;
  };

  const handleApply = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation(); // Stop event bubbling

    // Check if today is selected and excluded
    if (dateRange.startDate) {
      const startDate = new Date(dateRange.startDate);
      const today = new Date();
      
      // Check if selected start date is today and today is excluded
      if (
        startDate.getDate() === today.getDate() &&
        startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear() &&
        isTodayExcluded()
      ) {
        setDateError("You cannot pick today's day");
        // Close the calendar even when there's an error
        setIsOpen(false);
        return;
      }
      
      // Also check end date if range selection is enabled
      if (lastNodeResponse?.dateRange !== false && dateRange.endDate) {
        const endDate = new Date(dateRange.endDate);
        const todayTime = today.getTime();
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        
        // Check if today falls within the selected range and is excluded
        if (todayTime >= startTime && todayTime <= endTime && isTodayExcluded()) {
          setDateError("You cannot pick today's day");
          // Close the calendar even when there's an error
          setIsOpen(false);
          return;
        }
      }
    }
    
    // Clear any previous errors
    setDateError(null);
    // Format the date range as a string and set it in a hidden field
    setFieldValue('formattedDateRange', formatDateRangeForSubmission());
    // Set that user has selected a date
    setHasSelected(true);
    // Close the calendar
    setIsOpen(false);
  };

  return (
    <StyledWidgetInputBox
      disabled={typingStatus ? true : false}
      error={getIn(errors, 'formattedDateRange') && getIn(touched, 'formattedDateRange') || dateError}
    >
      <DatePickerContainer>
        <DatePickerDisplay onClick={() => setIsOpen(!isOpen)}>
          {formatDateDisplay()}
        </DatePickerDisplay>
        
        <Backdrop isOpen={isOpen} onClick={() => setIsOpen(false)} />
        
        <DatePickerWrapper isOpen={isOpen}>
          <DateRange
            ranges={[{
              startDate: dateRange.startDate || validFromDate,
              endDate: dateRange.endDate || finalToDate,
              key: 'selection'
            }]}
            onChange={handleSelect}
            onDayClick={lastNodeResponse?.dateRange === false ? handleDateClick : undefined}
            months={1}
            direction="vertical"
            minDate={validFromDate}
            maxDate={finalToDate}
            disabledDay={isDateDisabled}
            rangeColors={['#3d91ff']}
            dragSelectionEnabled={lastNodeResponse?.dateRange !== false}
            showPreview={lastNodeResponse?.dateRange !== false}
            showDateDisplay={lastNodeResponse?.dateRange !== false}
            locale={enUS}
            {...otherProps}
          />
          <ButtonContainer>
            <AppSmallButton variant="grey" onClick={() => setIsOpen(false)}>
              Cancel
            </AppSmallButton>
            <AppSmallButton variant="primary" onClick={handleApply}>
              Save
            </AppSmallButton>
          </ButtonContainer>
        </DatePickerWrapper>
      </DatePickerContainer>
      
      <FastField name="formattedDateRange">
        {({ field }: any) => (
          <StyledInputField
            {...field}
            type="hidden"
            value={formatDateRangeForSubmission()}
          />
        )}
      </FastField>

      <AppSendSubmitButton />

      {dateError ? (
        <AppErrorMessage error={dateError} visible={true} />
      ) : (
        <AppErrorMessage
          error={getIn(errors, 'formattedDateRange')}
          visible={getIn(touched, 'formattedDateRange')}
        />
      )}
    </StyledWidgetInputBox>
  );
};

export default AppFlowNewDatePicker;
