import * as Yup from "yup";
import ValidateCountryMobileNumber from "./CountryMobileRegex";
export const URLREGEX =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const MeetingSchema = Yup.object().shape({
  selected_date: Yup.string().required("Date is required").label("Date"),
  selected_time: Yup.string().required("Time is required").label("Time"),
});
export const UrlSchema = Yup.object().shape({
  url: Yup.string()
    .matches(URLREGEX, "Please enter a valid url")
    .required()
    .label("Url"),
});

export const NameSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .max(100)
    .required().trim()
    .label("Name"),
});
export const TextSchema = Yup.object().shape({
  textField: Yup.string().min(1).max(244).required().label("Input"),
});
export const FaqInputSchema = Yup.object().shape({
  query: Yup.string().min(1).max(244).required().label("Input"),
});
export const LiveChatSchema = Yup.object().shape({
  livechat: Yup.string()
    .label("Field")
    .test(
      "is-not-whitespace",
      "Field is a required field",
      (value: any, ctx: any) => {
        const media = ctx?.parent?.media;
        const trimmedValue = value?.trim();
        return !!trimmedValue || media?.source?.length > 0;
      }
    )
    .when(["media"], (key1: any, schema: any) => {
      return key1 && key1?.source?.length > 0
        ? schema.optional()
        : schema.required().min(1, "Field is a required field");
    }),
  media: Yup.mixed().label("Media"),
});

export const OtpSchema = Yup.object().shape({
  otp: Yup.number()
    .min(1000, "Please enter a valid otp")
    .max(999999, "Please enter a valid otp")
    .required()
    .label("OTP"),
});
export const NumberSchema = Yup.object().shape({
  number: Yup.number().min(0).max(999999999).required().label("Number"),
});
export const SelectOptionSchema = Yup.object().shape({
  selectedOption: Yup.lazy((value: any) => {
    if (value === 0 || value === "0") {
      return Yup.string()
        .required("Select a option is required")
        .min(2, "Select a desired option")
        .label("Select field");
    } else {
      return Yup.string()
        .required("Select a option is required")
        .min(1, "Select a desired option")
        .label("Select field");
    }
  }),
});

export const QuerySchema = Yup.object().shape({
  query: Yup.string().min(1).max(300).required().label("Query"),
});
export const EndChatFeedbackSchema = Yup.object().shape({
  rating: Yup.number().min(1).max(5).required().label("rating"),
  feedback: Yup.string().max(300).notRequired().label("feedback"),
});

export const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .matches(
      /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      "Please enter a valid email"
    )
    .required()
    .trim()
    .label("Email"),
});

export const UploadSchema = Yup.object().shape({
  attachment: Yup.mixed().required().label("File"),
});

export const DatePickerSchema = Yup.object().shape({
  formattedDateRange: Yup.string()
    .required("Please select a date")
    .label("Date"),
});

export const MobileSchema = Yup.object().shape({
  mobile: Yup.string()
    .min(1, "Invalid Mobile format")
    .max(19)
    .required()
    //@ts-ignore
    .test("strongPhoneTest", null, (value: any, ctx: any) => {
      let mobileCountryFormat: any = ctx?.parent?.mobileCountryFormat;
      let iso2: any = mobileCountryFormat?.iso2;

      const appendingPlusSignToMobileValue = "+" + value;

      const result = ValidateCountryMobileNumber(
        iso2,
        appendingPlusSignToMobileValue
      );

      if (result) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Please enter valid Mobile number for ${mobileCountryFormat?.name}`,
          null,
          "mobile"
        );
      }

      // let format: any = mobileCountryFormat?.format
      //   ?.replaceAll(".", "0")
      //   ?.replaceAll(" ", "")
      //   ?.replaceAll("(", "")
      //   ?.replaceAll(")", "")
      //   ?.replaceAll("-", "")
      //   ?.replaceAll("+", "");
      // let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
      // let formatWithoutDialCode: any = format?.substring(dialCodeLength);
      // let valueWithoutDialCode: any = value?.substring(dialCodeLength);

      // if(iso2 === "in" && valueWithoutDialCode?.length == 10){
      //   return true;
      // }

      // //temporary skip for all countries
      // if(iso2 !== "in" && valueWithoutDialCode?.length >= 8 && valueWithoutDialCode?.length <= 14){
      //   return true;
      // }

      // //bangladesh
      // if(iso2 === "bd" && valueWithoutDialCode?.length >= 10){
      //   return true;
      // }

      // //saudi arabia
      // if(iso2 === "sa" && (valueWithoutDialCode?.length == 9 ||  valueWithoutDialCode?.length == 10)){
      //   return true;
      // }

      // if (formatWithoutDialCode?.length === valueWithoutDialCode?.length) {
      //   let count = 0;
      //   for (var i = 0; i < valueWithoutDialCode?.length; i++) {
      //     if (
      //       valueWithoutDialCode.charAt(i) === 0 ||
      //       valueWithoutDialCode.charAt(i) === "0"
      //     ) {
      //       count = count + 1;
      //     }
      //   }
      //   if (formatWithoutDialCode?.length === count) {
      //     return new Yup.ValidationError(
      //       `Please enter valid Mobile number.`,
      //       null,
      //       "mobile"
      //     );
      //   } else {
      //     return true;
      //   }
      // } else {
      //   return new Yup.ValidationError(
      //     `Please enter valid Mobile format for ${mobileCountryFormat?.name}`,
      //     null,
      //     "mobile"
      //   );
      // }
    })
    .label("Mobile"),
});

export const FeedbackSchema = Yup.object().shape({
  rating: Yup.number()
    // .min(3)
    .max(5)
    .required("Rating is required")
    .label("Rating"),
  feedback: Yup.string().notRequired().label("feeback"),
});

export const OutsideBusinessHoursSchema = Yup.object().shape({
  name: Yup.string()
    .max(30)
    .label("Name")
    .test("Unique name Test", {}, (value: any, ctx: any) => {
      let nameEnabled: boolean = ctx?.parent?.enabled?.name;
      if ((nameEnabled && !value) || value?.length < 1) {
        return ctx?.createError({
          message: "Name is a required field",
        });
      } else {
        return true;
      }
    }),
  email: Yup.string()
    .email()
    .max(30)
    .label("Email")
    .test("Unique email Test", {}, (value: any, ctx: any) => {
      let emailEnabled: boolean = ctx?.parent?.enabled?.email;
      if ((emailEnabled && !value) || value?.length < 1) {
        return ctx?.createError({
          message: "Email is a required field",
        });
      } else {
        return true;
      }
    }),
  // phone: Yup.string().min(1).max(20).required().label("Phone"),
  phone: Yup.string()
    .min(1, "Invalid Mobile format")
    .max(19)
    // .required("Mobile is required")
    //@ts-ignore
    .test("strongPhoneTest", null, (value: any, ctx: any) => {
      let phoneEnabled: boolean = ctx?.parent?.enabled?.phone;
      if (!phoneEnabled) {
        return true;
      }

      let mobileCountryFormat: any = ctx?.parent?.mobileCountryFormat;
      let format: any = mobileCountryFormat?.format
        ?.replaceAll(".", "0")
        ?.replaceAll(" ", "")
        ?.replaceAll("(", "")
        ?.replaceAll(")", "")
        ?.replaceAll("-", "")
        ?.replaceAll("+", "");
      let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;
      let formatWithoutDialCode: any = format?.substring(dialCodeLength);
      let valueWithoutDialCode: any = value?.substring(dialCodeLength);

      if (formatWithoutDialCode?.length == valueWithoutDialCode?.length) {
        let count = 0;
        for (var i = 0; i < valueWithoutDialCode?.length; i++) {
          if (
            valueWithoutDialCode.charAt(i) == 0 ||
            valueWithoutDialCode.charAt(i) == "0"
          ) {
            count = count + 1;
          }
        }
        if (formatWithoutDialCode?.length == count) {
          return new Yup.ValidationError(
            `Please enter valid Mobile number.`,
            null,
            "phone"
          );
        } else {
          return true;
        }
      } else {
        return new Yup.ValidationError(
          `Please enter valid Mobile format for ${mobileCountryFormat?.name} ${formatWithoutDialCode?.length} digits required`,
          null,
          "phone"
        );
      }
    })
    .label("Phone"),
});
